import React from 'react';
import './Footer.scss';

const Footer = () => {
    return(
    <footer className='footer'>
        <div>
            <p>© Pamoja 2024</p>
        </div>
    </footer>
)};
 
export default Footer;