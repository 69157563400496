import React, {useRef} from 'react';
//import './Prescreening.scss';
//import Select from 'react-select'
import './Demo.scss';
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';

const Demo = () => {
    const form = useRef(null);

    const submitRequest = (e) => {
        e.preventDefault();
        emailjs
        .sendForm('service_5hi127u', 'template_vu6qjpf', form.current, {
          publicKey: 'XXygdMsx5D8WGOLZq',
        })
        .then(
          () => {
            toast.success('Request succesfully submitted!')
          },
          (error) => {
            toast.error('Error submitting request, please try again later!')
            console.log(error.text)
          },
        );
    }

    return (
        <div className="Demo">
            <div className="description">
                <h1 className="unico">REQUEST DEMO</h1>
                <form ref={form} onSubmit={submitRequest}>
                    <div className='field'>
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" name="name" placeholder="Name" required/>
                    </div>
                    <div className='field'>
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" placeholder="Email" required/>
                    </div>
                    <div className='field'>
                        <label htmlFor="company">Company</label>
                        <input type="text" id="company" name="company" placeholder="Company" required/>
                    </div>
                    <div className='field'>
                        <label htmlFor="message">Message</label>
                        <textarea id="message" name="message" placeholder="Message" />
                    </div>
                    <input className="submit" type="submit" value="Submit" />
                </form>
            </div>
        </div>
    );
};


export default Demo;