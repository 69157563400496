import React from 'react';
//import './Prescreening.scss';
//import Select from 'react-select'
import './About.scss';

const About = () => {
    return (
        <div className="About">
            <div className="description">
                <h1 className="unico">ABOUT</h1>
                <p>
                    Hi, I'm Dan Chepkwony, the founder.
                    I'm currently a software engineer at CVS Health.
                </p>
                <p>
                    With an MVP built, I am continuing to grow my team of partners and investors.
                    In addition, I'm networking with health systems in my area to pilot the application.
                </p>
                <p>
                    Connect with me on <a href="https://www.linkedin.com/in/danchepkwony/" target="_blank" rel="noopener noreferrer">LinkedIn</a> or <a href="mailto:dan.k.chepkwony@gmail.com">email</a> me if you're interested!
                </p>
            </div>
        </div>
    );
};


export default About;