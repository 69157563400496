import { Route, Routes } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import Home from './pages/Home/Home';
import About from './pages/About/About';
import Demo from './pages/Demo/Demo';
import Navigation from './components/Navigation/Navigation';
import Footer from './components/Footer/Footer';
import { Toaster } from 'react-hot-toast';
import './App.scss';

function App() {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyCECSpquqhL1Pt629Yj3H8wCxzLD2WN9Rk",
    authDomain: "commune-info-c978a.firebaseapp.com",
    projectId: "commune-info-c978a",
    storageBucket: "commune-info-c978a.appspot.com",
    messagingSenderId: "63185260283",
    appId: "1:63185260283:web:c5233a5def3e0a67c22a5e",
    measurementId: "G-CC00FQ9Y4C"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/about" Component={About} />
        <Route path="/demo" Component={Demo} />
      </Routes>
      <Footer />
      <Toaster />
    </>
  );
}

export default App;


