import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Navigation.scss'
import logo from '../../images/logo.png';

const Navigation = () => {
    const [windowWidth, setWindowSize] = useState(window.innerWidth);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <nav className="Navigation unico">
            {/* { windowWidth > 600 } */}
            {1 ? (
                <>
                <div>
                    <NavLink to='/' className="blend logo unico" onClick={() => { }}><img src={logo} alt="Logo representing community"/>Pamoja</NavLink>
                </div>
                <div className="Normal">
                    <NavLink to="/" exact className={({ isActive, isPending }) =>
                        isPending ? "link link-active" : isActive ? "link link-active" : "link"
                    }>HOME<div className="link-underline" /></NavLink>
                    <NavLink to='/demo' className={({ isActive, isPending }) =>
                        isPending ? "link link-active" : isActive ? "link link-active" : "link"
                    }>DEMO<div className="link-underline" /></NavLink>
                </div>
                </>
            ) : (
                <>
                <div>
                    <NavLink to='/' className="blend logo unico" onClick={() => { }}>commune</NavLink>
                </div>
                <div className="Dropdown">
                    <img className="button" onClick={() => { }} alt="Open Page Links" />
                    <ul className="content">
                        <li className="item">
                            <NavLink to='/' className="dropdown-link" onClick={() => { }}>HOME</NavLink>
                        </li>
                        <li className="item">
                            <NavLink to='/demo' activeClassName="dropdown-link" className="dropdown-link" onClick={() => { }}>DEMO</NavLink>
                        </li>
                        <div className="break"></div>
                        <div className="break"></div>
                    </ul>
                </div>
                </>
            )}
        </nav>
    );
}

export default Navigation; 