import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
//import './Prescreening.scss';
//import Select from 'react-select'
import './Home.scss';
import friends from '../../images/mixed_age_friends_v5.png';
import sparks from '../../images/sparks_v2.gif';
import circle from '../../images/circle.gif';
import doc from '../../images/Doc.svg';
import selected from '../../images/selected.png';
import unselected from '../../images/unselected.png';
import cigarette from '../../images/cigarRAT.png';
import smoke from '../../images/smoke_v1.png';
import hand from '../../images/hand_v4.png';
import referral_person1 from '../../images/tree_v1_person1.png';
import referral_person2 from '../../images/tree_v1_person2.png';
import referral_tree from '../../images/tree_v1_tree.png';
import downArrow from '../../images/down-arrow.png';
import explode from '../../images/explode.svg';
import doc_mobile from '../../images/Doc.svg';
import selected_mobile from '../../images/selected.png';
import referral_tree_mobile from '../../images/tree_v1_tree.png';
import explode_mobile from '../../images/explode.svg';


const Home = () => {
    const [windowWidth, setWindowSize] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [toggleImage, setToggleImage] = useState(false);
    const [toggleScroll, setToggleScroll] = useState(false);
    const [toggleSmile, setToggleSmile] = useState(false);
    const scrollImageContainer = useRef();

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(window.innerWidth);
            if (window.innerWidth < 768) {
                setIsMobile(true)
            }
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let matchMedia = gsap.matchMedia();

        // var friendsSlide = gsap.from("#friends", {
        //     y: -60
        // })

        // ScrollTrigger.create({
        //     scrub: true,
        //     start: '+=10',
        //     end: "+=600",
        //     animation: friendsSlide
        // });

        var brownBarSlide = gsap.to("#brown-bar-landing", {
            y: -100
        })

        ScrollTrigger.create({
            scrub: true,
            start: '+=10',
            end: "+=600",
            animation: brownBarSlide
        });

        matchMedia.add("(min-width: 800px)", () => {
            var handTween = gsap.from("#hand", {
                x: 30,
                y: -40,
                ease: "expoScale(0.5,7,power3.out)"
            })

            ScrollTrigger.create({
                scrub: 1,
                trigger: '#first-split',
                start: 'top top',
                end: "+=1000",
                pin: '#first-split',
                animation: handTween,
                onUpdate: (self) => {
                    self.progress > 0.5 ? setToggleImage(true) : setToggleImage(false);
                    self.progress < 1 ? setToggleScroll(false) : setToggleScroll(true);
                }
            });

            var treeTween = gsap.timeline().from("#tree", {
                autoAlpha: 0,
                y: 20
            }).from("#second-person1", {
                autoAlpha: 0,
                x: -20
            }).from("#second-person2", {
                autoAlpha: 0,
                x: 20
            })

            ScrollTrigger.create({
                scrub: 1,
                trigger: '#second-split',
                start: 'top top',
                end: "+=1000",
                pin: '#second-split',
                animation: treeTween,
                onUpdate: (self) => {
                    self.progress > 0.6 ? setToggleImage(true) : setToggleImage(false);
                }
            });


            var smileAnim = gsap.timeline().to("#smile-face", {
                scale: 1.25,
                ease: "back.out(2)"
            }).to("#happy-explode", {
                scale: 3,
                autoAlpha: 0
            })

            ScrollTrigger.create({
                scrub: 1,
                trigger: '#third-split',
                start: 'top top',
                end: "+=1000",
                pin: '#third-split',
                animation: smileAnim,
                onUpdate: (self) => {
                    self.progress > 0.4 ? setToggleSmile(true) : setToggleSmile(false);
                }
            });

            var smokeSlideUp = gsap.from("#cigarette-with-smoke", {
                y: 100,
                x: 50
            })

            ScrollTrigger.create({
                scrub: true,
                trigger: '#lonely-section',
                start: 'top center',
                animation: smokeSlideUp,
            });
        });
    }, [scrollImageContainer]);

    return (
        <div className="Home">
            <div className='content'>
                <div className="description">
                    <h1 className="unico"><span>SOCIAL CONNECTION AS MEDICINE</span></h1>
                    <p><i>Addressing loneliness through social prescription</i></p>
                    <NavLink to='/demo' className="cta" onClick={() => { }}>Request a Demo</NavLink>
                </div>
                <div className='landing-image'>
                    <img id="friends" src={friends} alt="Social Connection as Medicine" />
                    <img className="sparks" src={sparks} alt="" />
                </div>
                <div className='brown-bar-landing' id="brown-bar-landing">
                </div>
                <div className="details" >
                    <h1 className="unico" id="details-header">HOW IT WORKS</h1>
                    <div className='split' id="first-split">
                        <div id='keep-scrolling' class={toggleScroll ? 'keep-scrolling zero-opacity' : 'keep-scrolling'}>
                            <img src={downArrow} alt="arrow pointing down" />
                            <i>Keep scrolling</i>
                        </div>
                        <div className='scroll-image-container' id="first-scroll-image-container" >
                            <img src={isMobile ? (toggleImage ? selected : unselected) : selected_mobile} alt="Social Connection as Medicine" id="first" />
                            <img className="hand" src={hand} alt="" id="hand" />
                        </div>
                        <div className='text' id="first-details-text">
                            <h2 className="unico">
                                <span>
                                    <span>1.</span>
                                    <img className="circle" src={circle} alt="" />
                                </span>
                                SCREENING
                            </h2>
                            <p>
                                With our integrated software, clincians can screen patients for loneliness using
                                conventional loneliness assessments.
                            </p>
                            <p>
                                Clinicians can use these scores to decide how beneficial or necessary social prescription
                                will be. Clients can opt-in at will.
                            </p>
                        </div>
                    </div>
                    <div className='split' id="second-split">
                        <div className='scroll-image-container'>
                            <img src={referral_person1} alt="Social Connection as Medicine" id="second-person1" />
                            <img className="second" src={referral_person2} alt="Social Connection as Medicine" id="second-person2" />
                            <img className="second" src={referral_tree} alt="" id="tree" />
                        </div>
                        <div className='text' id="second-details-text">
                            <h2 className="unico">
                                <span>
                                    <span>2.</span>
                                    <img className="circle" src={circle} alt="" />
                                </span>
                                REFERRAL
                            </h2>
                            <p>
                                Once referred, our team will virtually meet with the client to discuss their social
                                needs and preferences and set them up with a local volunteer opportunity.
                            </p>
                            <p>

                                Volunteering allows clients to build social connection in their local community,
                                while also boosting their confidence and sense of purpose.
                            </p>
                        </div>
                    </div>
                    <div className='split' id="third-split">
                        <div className='scroll-image-container'>
                            <div className='third-image-container'>
                                <svg id="smile-face" className={toggleSmile ? 'smile-face smile-bright' : 'smile-face smile-dark'} width="249" height="249" viewBox="0 0 249 249" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 124.846C1 157.692 14.048 189.193 37.2737 212.419C60.4994 235.644 92.0001 248.692 124.846 248.692C157.692 248.692 189.193 235.644 212.419 212.419C235.644 189.193 248.692 157.692 248.692 124.846C248.692 92.0001 235.644 60.4994 212.419 37.2737C189.193 14.048 157.692 1 124.846 1C92.0001 1 60.4994 14.048 37.2737 37.2737C14.048 60.4994 1 92.0001 1 124.846Z" fill='currentColor' />
                                    <path d="M1 124.846C1 157.692 14.048 189.193 37.2737 212.419C60.4994 235.644 92.0001 248.692 124.846 248.692C157.692 248.692 189.193 235.644 212.419 212.419C235.644 189.193 248.692 157.692 248.692 124.846C248.692 92.0001 235.644 60.4994 212.419 37.2737C189.193 14.048 157.692 1 124.846 1C92.0001 1 60.4994 14.048 37.2737 37.2737C14.048 60.4994 1 92.0001 1 124.846V124.846Z" stroke="#45413C" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path id="mouth-smile" className={toggleSmile ? '' : 'invisible'} d="M155.808 174.261C146.643 180.384 135.868 183.653 124.846 183.653C113.824 183.653 103.049 180.384 93.8846 174.261" stroke="#45413C" stroke-width="5" stroke-linecap="round" />
                                    <path id="mouth-frown" className={toggleSmile ? 'invisible' : ''} d="M155.808 174.261C146.643 180.384 135.868 183.653 124.846 183.653C113.824 183.653 103.049 180.384 93.8846 174.261" stroke="#45413C" stroke-width="5" stroke-linecap="round" />
                                    <path d="M66.0193 118.654C66.0193 119.878 65.6561 121.076 64.9757 122.094C64.2953 123.112 63.3281 123.906 62.1967 124.375C61.0652 124.843 59.8201 124.966 58.6189 124.727C57.4177 124.488 56.3143 123.898 55.4483 123.032C54.5823 122.166 53.9926 121.063 53.7536 119.862C53.5147 118.661 53.6373 117.416 54.106 116.284C54.5747 115.153 55.3684 114.185 56.3867 113.505C57.405 112.825 58.6022 112.461 59.827 112.461C61.4693 112.461 63.0443 113.114 64.2056 114.275C65.3669 115.436 66.0193 117.011 66.0193 118.654Z" fill="#45413C" stroke="#45413C" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M183.673 118.654C183.673 119.878 184.036 121.076 184.717 122.094C185.397 123.112 186.364 123.906 187.496 124.375C188.627 124.843 189.872 124.966 191.073 124.727C192.275 124.488 193.378 123.898 194.244 123.032C195.11 122.166 195.7 121.063 195.939 119.862C196.178 118.661 196.055 117.416 195.586 116.284C195.118 115.153 194.324 114.185 193.306 113.505C192.287 112.825 191.09 112.461 189.865 112.461C188.223 112.461 186.648 113.114 185.487 114.275C184.325 115.436 183.673 117.011 183.673 118.654Z" fill="#45413C" stroke="#45413C" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div className="happy-explode-container">
                                    <svg id="happy-explode" className={toggleSmile ? "happy-explode" : "invisible"} width="248" height="248" viewBox="0 0 248 248" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <ellipse cx="53" cy="60.5" rx="20" ry="19.5" fill="#CF7E33" />
                                        <circle cx="93.5" cy="40.5" r="9.5" fill="#CF7E33" />
                                        <circle cx="149.5" cy="202.5" r="13.5" fill="#CF7E33" />
                                        <circle cx="189.5" cy="206.5" r="9.5" fill="#CF7E33" />
                                        <circle cx="201.5" cy="176.5" r="6.5" fill="#CF7E33" />
                                    </svg>
                                </div>
                            </div>
                            <div className='third-image-container-mobile'>
                                <img src={doc} alt="Social Connection as Medicine" id="third" />
                            </div>
                        </div>
                        <div className='text' id="third-details-text">
                            <h2 className="unico">
                                <span>
                                    <span>3.</span>
                                    <img className="circle" src={circle} alt="" />
                                </span>
                                RESULTS
                            </h2>
                            <p>
                                Clinicians will be able to track a client's attendance throughout the program as
                                well as witness improvements in their loneliness scores.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="lonely" id="lonely-section">
                    <h1 className="unico">AMERICA IS LONELY</h1>
                    <div>
                        <p>
                            Dr. Vivek Murthy, US Surgeon General, called loneliness "<b>an underappreciated public health crisis</b>.”
                            Loneliness doesn't exclusively affect your mental health;
                            it can be as <a target="_blank" rel="noopener noreferrer" href="https://doi.org/10.1177/1745691614568352"><b>deadly as smoking 15 cigarettes daily</b></a>.
                            US Healthcare has yet to target loneliness as a form of preventative care.
                        </p>
                        <p>
                            Social prescription has already been piloted in the UK and Canada.
                            According to Alliance for Healthier Communities, participants in their research pilot
                            reported a <a target="_blank" rel="noopener noreferrer" href="https://www.allianceon.org/sites/default/files/documents/social_prescribing_2_pager_infographic_final_17may2022.pdf"><b>49% decrease in loneliness, 14% decrease in emergency room visits, and 20.8% reduction
                                in costs</b></a> to the health care system. We plan to replicate this impact in the United States.
                        </p>
                        <div id="cigarette-with-smoke">
                            <img className="cigarettes" src={cigarette} alt="Hand holding lit cigarettes" />
                            <img className="smoke" src={smoke} alt="Cigarette Smoke" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Home;